import React, { useMemo } from 'react';

import Layout from 'sections/Layout';

import Hero from 'sections/common/Hero';
import Content from 'sections/Guides/Content';
import ScreverInActionSec from 'sections/common/ScreverInAction';

import useAllGuides from 'hooks/graphql/useAllGuides';
import useCurrentLocale from 'hooks/useCurrentLocale';

import { GUIDES_INDEX } from 'mocks/guides-index';

const GuidesIndex = () => {
  const allGuides = useAllGuides();

  const { currentLocale } = useCurrentLocale();

  const guides = useMemo(() => {
    const _guides = allGuides.filter(
      (item) => item.language.slug === currentLocale
    );

    return _guides;
  }, [allGuides, currentLocale]);

  const heroData = {
    title: GUIDES_INDEX[currentLocale].hero.title,
    subtitle: GUIDES_INDEX[currentLocale].hero.subtitle,
    langSwitcherTitle: GUIDES_INDEX[currentLocale].langSwitcher,
  };

  const heroStyles = {
    color: '#9A7CF1',
    background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='594' height='416' fill='none' viewBox='0 0 594 416'%3E%3Cpath stroke='%23231F20' stroke-linejoin='round' stroke-width='4' d='M307.821 131.253c392.328 172.843 364.393-589.377 2.635-52.78-361.759 536.596 453.954 348.347-16.683 0-470.637-348.348-325.485 183.57 105.64 1.48C830.538-102.138 5.77-390.39 155.917 78.473c150.148 468.862-409.173-194.406 151.904 52.78Z'/%3E%3C/svg%3E")`,
  };

  return (
    <Layout
      seo={GUIDES_INDEX['en'].seo}
      headerDarkColor={true}
      headerWhiteBg={true}
      requestButtonBlue={true}
    >
      {(commonData) => (
        <>
          <Hero
            data={heroData}
            color={heroStyles.color}
            background={heroStyles.background}
          />

          <Content lang={currentLocale} data={guides} />

          <ScreverInActionSec />
        </>
      )}
    </Layout>
  );
};

export default GuidesIndex;
