import React from 'react';
import { Link } from 'gatsby';

import { tags, title, list, guideItem } from './styles.module.scss';

const GuideContent = ({ data }) => {
  return (
    <section>
      <div className="scr-wrap">
        <ul className={list}>
          {data.map((post) => {
            return (
              <li key={post.uri}>
                <Link to={post.uri} className={guideItem}>
                  <img
                    src={post?.guide?.guideHero?.guideHeroBanner?.sourceUrl}
                    alt={post?.guide?.guideHero?.guideHeroBanner?.altText}
                    srcSet={post?.guide?.guideHero?.guideHeroBanner?.srcSet}
                  />

                  <p className={tags}>
                    {post?.tags?.nodes.length > 0 &&
                      post?.tags?.nodes?.map((tag) => {
                        return (
                          <span key={tag.name} className="scr-reg">
                            {tag.name}
                          </span>
                        );
                      })}
                  </p>

                  <p className={title}>{post?.title}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default GuideContent;
