export const GUIDES_INDEX = {
  en: {
    seo: {
      title: 'Guides and e-books | Screver Feedback Management Solution',
      metaDesc:
        'Take a deep dive into the Screver Guides and e-books. Get expert knowledge from our experts on Customer Experience (CX) and Employee Experience (EX).',
      opengraphUrl: 'https://screver.com/guides',
    },
    hero: {
      title: 'Guides and e-books',
      subtitle:
        'Take a deep dive into the Screver guides. Get expert knowledge on Customer Experience (CX) and Employee Experience (EX).',
    },
    langSwitcher: 'Guide language',
  },
  de: {
    seo: {
      title: 'Leitfäden und E-Books | Screver Feedback Management Solution',
      metaDesc:
        'Tauchen Sie tief in die Screver Leitfäden und E-Books ein. Holen Sie sich Expertenwissen von unseren Experten für Customer Experience (CX) und Employee Experience (EX).',
      opengraphUrl: 'https://screver.com/guides',
    },
    hero: {
      title: 'Leitfäden und E-Books',
      subtitle:
        'Tauchen Sie tief in die Screver-Handbücher ein. Holen Sie sich Expertenwissen zu Customer Experience (CX) und Employee Experience (EX).',
    },
    langSwitcher: 'Sprache des Leitfadens',
  },
};
