import { useStaticQuery, graphql } from 'gatsby';

const allGuides = graphql`
  query {
    allWpGuide {
      nodes {
        id
        title
        slug
        uri
        content
        tags {
          nodes {
            name
            slug
          }
        }
        categories {
          nodes {
            slug
            name
          }
        }
        guide {
          guideInActionForm
          guideHero {
            guideHeroTitle
            guideHeroDescription
            guideHeroBanner {
              altText
              sourceUrl
              srcSet
            }
            guideHeroBackButton
          }
          guideCustomTitleForArticle
          guideAttachFileToDownload {
            mediaItemUrl
          }
        }
        seo {
          readingTime
        }
        language {
          code
          name
          locale
          slug
        }
      }
    }
  }
`;

const useAllGuides = () => {
  const {
    allWpGuide: { nodes },
  } = useStaticQuery(allGuides);

  return nodes;
};

export default useAllGuides;
